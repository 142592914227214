<template>
  <div>
    <div class="box">
      <div class="box-header">
        <h2>{{ $t('ACCOUNT.PERSONAL.HEADING') }}</h2>
      </div>
      <div class="box-content">
        <PersonalDataForm :update-pdv-state="updatePdvState" />
        <AccountPageActions
          :save="saveAddressData"
          :disabled="!hasChanged"
          @on-revert="reset"
        />
      </div>
    </div>
    <div class="box">
      <div class="box-header">
        <h2>{{ $t('ACCOUNT.EMAIL.HEADING') }}</h2>
      </div>
      <div class="box-content">
        <EmailForm />
      </div>
    </div>
  </div>
</template>

<script>
import PersonalDataForm from './PersonalDataForm.vue';
import AccountPageActions from 'src/app/partnerarea/account/AccountPageActions.vue';
import EmailForm from './EmailForm.vue';
import { mapMutations, mapActions, mapGetters } from 'vuex';

export default {
  name: 'Personal',
  components: {
    PersonalDataForm,
    AccountPageActions,
    EmailForm,
  },
  computed: {
    ...mapGetters({
      hasChanged: 'userAddress/hasChanged',
    }),
  },
  methods: {
    ...mapActions({
      saveAddressData: 'userAddress/saveAddressData',
      updatePdvState: 'userAddress/fetchPdvState',
    }),
    ...mapMutations({
      reset: 'userAddress/reset',
    }),
  },
};
</script>

<style lang="scss" scoped>
.box:first-child {
  margin-bottom: 24px;
}
</style>
