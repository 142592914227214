<template>
  <div>
    <div v-if="initialized" class="dashboard overflow-y">
      <div class="dashboard-content">
        <h1 v-if="username">{{ $t('ACCOUNT.GREETING') }}, {{ username }}</h1>
        <h1 v-else>
          {{ $t('NAV.DASHBOARD') }}
        </h1>
        <AccountWarnings />
        <div
          v-if="!alerts.pdv && alerts.paymentDataMissing"
          class="color-box payment-data-warning"
          @click="goToAccountPage('finance')"
        >
          <Icon icon="bell"></Icon>
          {{ $t('DASHBOARD.MISSING_PAYMENT_DATA') }}
        </div>
        <div
          v-if="alerts.taxFormExpires"
          class="color-box yellow"
          @click="goToAccountPage('taxation')"
        >
          <Icon icon="bell"></Icon>
          {{ $t('DASHBOARD.TAXATION_FORM_ABOUT_TO_EXPIRE') }}
        </div>
        <OnboardingModule />
        <div class="dashboard-row">
          <NotificationsModule class="notification-module" />
          <CreditModule class="credit-module" />
        </div>
        <div class="dashboard-row pos-specific-modules">
          <DmsModule />
          <VolumeCommissionModule />
        </div>
        <div class="dashboard-row">
          <Sales />
        </div>
        <div class="dashboard-row promo-and-news">
          <SpreadAppModule class="spreadapp-module" />
          <RecapModule class="recap-module" />
        </div>
        <div class="dashboard-row promo-and-news">
          <ShopPromotionsModule class="shop-promo-module" />
          <YoutubeModule class="youtube-module" />
        </div>
        <News :count="4" />
      </div>
    </div>
    <div v-else class="loader">
      <img src="/images/loader.svg" class="sprd-loader" />
    </div>
  </div>
</template>

<script>
import CreditModule from './CreditModule.vue';
import DmsModule from './DmsModule.vue';
import VolumeCommissionModule from './VolumeCommissionModule.vue';
import ShopPromotionsModule from './ShopPromotionsModule.vue';
import Sales from './Sales.vue';
import News from './News.vue';
import YoutubeModule from './YoutubeModule.vue';
import NotificationsModule from './NotificationsModule.vue';
import OnboardingModule from './OnboardingModule.vue';
import RecapModule from './RecapModule.vue';
import SpreadAppModule from './SpreadAppModule.vue';
import { mapGetters } from 'vuex';
import AccountWarnings from 'src/app/partnerarea/account/AccountWarnings.vue';
import { triggerNps } from 'src/app/components/nps/NpsService';

export default {
  name: 'Dashboard',
  components: {
    CreditModule,
    DmsModule,
    VolumeCommissionModule,
    Sales,
    News,
    NotificationsModule,
    OnboardingModule,
    AccountWarnings,
    ShopPromotionsModule,
    YoutubeModule,
    RecapModule,
    SpreadAppModule,
  },
  data() {
    return {
      initialized: false,
    };
  },
  computed: {
    ...mapGetters({
      alerts: 'account/alerts',
      username: 'identity/customUsername',
    }),
  },
  async created() {
    await this.$store.state.settings.onDataLoaded;
    this.initialized = true;
    triggerNps({ delayInMs: 1000 });
  },
  methods: {
    goToAccountPage(section) {
      this.$router.push({ name: 'partnerarea.account', params: { section } });
    },
  },
};
</script>

<style lang="scss">
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/type';

.loader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100px;
    height: 100px;
  }
}

.dashboard {
  height: 100%;

  .text-sm {
    line-height: 1.5;
  }

  .dashboard-content {
    max-width: 1400px;
    padding: 0 20px 20px 20px;
    margin: 0 auto;

    & > h1 {
      margin: 24px 0;
    }
  }

  .dashboard-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 24px;

    & > div {
      background-color: $grey0;
      border-radius: $border-radius-medium;
      flex: 1;
    }

    .credit-module {
      min-width: 300px;
    }

    .notification-module {
      flex: 1;
      flex-basis: 400px;
    }

    .spreadapp-module {
      flex: 1;
      flex-basis: 300px;
    }

    .recap-module {
      flex: 1;
      flex-basis: 300px;
    }

    .shop-promo-module {
      flex: 1;
      flex-basis: 500px;
    }

    .youtube-module {
      min-width: 400px;
    }
  }

  .color-box {
    margin-bottom: 24px;
    cursor: pointer;
  }

  .payment-data-warning {
    padding: 10px;
    display: flex;
    align-items: center;

    .icon {
      font-size: 2em;
      margin-right: 10px;
    }

    a:hover,
    a:focus {
      color: darken(#fff, 10%);
    }
  }
}
</style>
