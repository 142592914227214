import userCoreService from '@/api/userAccount/userCoreService';
import { isEqual, deepCopy } from '@/utils';
import accountValidator from '@/api/validators/account/accountValidator';
import pdvService from '../../partnerDataValidation/service/pdvService';

function resetValidationErrorForField(validation, field) {
  if (validation.errors[field]) {
    delete validation.errors[field];
  }
  return validation;
}

function validateFields(state, field) {
  if (!state.validate) {
    return;
  }

  if (Array.isArray(field)) {
    field.forEach((f) => validateFields(state, f));
  } else {
    resetValidationErrorForField(state.validation, field);
    const validationResult = accountValidator.validateByFieldName(
      field,
      state.data,
    );
    state.validation = accountValidator.mergeResults(
      state.validation,
      validationResult,
    );
  }
}

export default {
  namespaced: true,
  state: {
    data: null,
    originalData: null,
    countries: null,
    pdvState: null,
    validate: false,
    validation: {
      errors: {},
      result: true,
    },
  },
  getters: {
    hasChanged: (state) => !isEqual(state.data, state.originalData),
    isCompany: (state) => typeof state.data.company === 'string',
  },
  mutations: {
    setData(state, data) {
      state.data = data;
      state.originalData = deepCopy(data);
    },
    setCountries(state, countries) {
      state.countries = countries;
    },
    setPdvState(state, pdvState) {
      state.pdvState = pdvState;
    },
    setValidation(state, validation) {
      state.validation = validation;
    },
    reset(state) {
      state.data = deepCopy(state.originalData);
    },
    setCompanyName(state, company) {
      state.data.company = company;
      validateFields(state, 'company');
    },
    deleteCompanyName(state) {
      delete state.data.company;
    },
    setLastName(state, lastName) {
      state.data.person.lastName = lastName;
      validateFields(state, 'lastName');
    },
    setFirstName(state, firstName) {
      state.data.person.firstName = firstName;
      validateFields(state, 'firstName');
    },
    setCountry(state, country) {
      state.data.country = {
        code: country.countryCode || country.code,
        value: country.name || country.value,
      };
      validateFields(state, 'country');
    },
    setState(state, newState) {
      state.data.state = newState;
      validateFields(state, 'state');
    },
    setStreet(state, street) {
      state.data.originalStreet = street;
      validateFields(state, 'street');
    },
    setStreet2(state, street2) {
      state.data.streetAnnex = street2;
      validateFields(state, 'street2');
    },
    setPostalCode(state, postalCode) {
      state.data.zipCode = postalCode;
      validateFields(state, 'postalCode');
    },
    setCity(state, city) {
      state.data.city = city;
      validateFields(state, 'city');
    },
  },
  actions: {
    async fetchAddressData({ commit, dispatch }) {
      dispatch('fetchPdvState');
      const data = await userCoreService.getAddressData();
      commit('setData', data);
    },
    saveAddressData({ state, commit, dispatch }, { forceValidation } = {}) {
      return new Promise((resolve, reject) => {
        dispatch('validate', { forceValidation });
        if (state.validation.result) {
          userCoreService.updateAddressData(state.data).then(
            (result) => {
              commit('setData', result);
              dispatch('fetchPdvState');
              dispatch('userTaxation/fetchTaxationData', null, { root: true });
              dispatch('userTrolley/ensureTrolleyStatusIsUpdated', null, {
                root: true,
              });
              resolve(result);
            },
            (error) => {
              reject(error);
            },
          );
        } else {
          reject();
        }
      });
    },
    async fetchCountries({ commit }) {
      const data = await userCoreService.getCountries();
      commit('setCountries', data);
    },
    async fetchPdvState({ commit }) {
      const data = await pdvService.fetchState();
      commit('setPdvState', data);
    },
    validate({ state, commit, getters }, { forceValidation }) {
      if (!forceValidation && !getters.hasChanged) {
        return;
      }

      state.validate = true;

      const fieldsToValidate = [
        'firstName',
        'lastName',
        'company',
        'country',
        'city',
        'postalCode',
        'street',
        'street2',
        'state',
      ];

      const val = accountValidator.validateMultipleFieldNames(
        fieldsToValidate,
        state.data,
      );
      commit('setValidation', val);
    },
  },
};
