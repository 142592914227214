<template>
  <p
    class="dms-publishing-counter"
    :class="{ 'limit-reached': publishingLimitReached }"
  >
    <span class="publishing-count">{{ sprdNumber(publishingCount) }} </span>
    <template v-if="publishingLimit.value">
      {{ ` / ${sprdNumber(publishingLimit.value)}` }}
    </template>
    <template v-else> /<Icon icon="infinity" /> </template>
  </p>
</template>

<script>
import { DMS_PROPERTIES } from '@/api/models/dms/dmsStates';
import { mapGetters } from 'vuex';

export default {
  name: 'DmsPublishingCounter',
  computed: {
    ...mapGetters({
      getProperty: 'dms/getProperty',
      publishingCount: 'dms/livePublishingCount',
      publishingLimitReached: 'dms/publishingLimitReached',
    }),
    publishingLimit() {
      return this.getProperty(DMS_PROPERTIES.PUBLISHING_LIMIT);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.dms-publishing-counter {
  margin: 0;
  display: inline-flex;
  align-items: center;

  .icon {
    height: 18px;
    width: 18px;
  }

  &.limit-reached {
    color: $pa-color-red-dark;
  }
}

.publishing-count {
  font-weight: bold;
}
</style>
