import accountService from '@/api/accountService/accountService';
import pdvService from '@/partnerDataValidation/service/pdvService';
import accountAlerts from '@/api/models/accountAlerts';
import MFA_STATES from '@/states/mfaStates';
import { useStatisticsStore } from '../stores/statistics';

export default {
  namespaced: true,
  state: {
    isAdmin: false,
    payoutLimitReached: false,
  },
  getters: {
    alerts: (state, getters, rootState, rootGetters) => {
      if (!rootState.userAddress.data) {
        return [];
      }

      const alerts = [];

      // PDV ALERTS
      if (state.pdvState && Boolean(pdvService.getUIState(state.pdvState))) {
        if (
          pdvService.getUIState(state.pdvState) ===
          pdvService.UI_STATES.LETTER_SENT
        ) {
          alerts.push(accountAlerts.PDV_LETTER_SENT);
        } else if (
          pdvService.getUIState(state.pdvState) ===
          pdvService.UI_STATES.PDV_FAILED
        ) {
          alerts.push(accountAlerts.PDV_FAILED);
        } else if (
          pdvService.getUIState(state.pdvState) ===
          pdvService.UI_STATES.PDV_FULLY_FAILED
        ) {
          alerts.push(accountAlerts.PDV_FULLY_FAILED);
        }
      }

      // TAXATION EU ALERTS
      if (SPRD.PLATFORM === 'EU' && state.payoutLimitReached) {
        if (rootGetters['userPaymentInfo/paymentDataMissing']) {
          alerts.push(accountAlerts.PAYMENT_DATA_MISSING);
        }

        if (
          getters.hasSavedAddress &&
          !rootGetters['userTaxation/taxationValid']
        ) {
          alerts.push(accountAlerts.TAXATION_INVALID);
        }

        if (
          getters.hasSavedAddress &&
          !rootGetters['userTaxation/taxationConsistent']
        ) {
          alerts.push(accountAlerts.TAXATION_INCONSISTENT);
        }

        if (getters.hasSavedAddress && !getters.taxationFormValid) {
          alerts.push(accountAlerts.TAX_FORM_INVALID);
        }
      }

      if (
        SPRD.PLATFORM === 'EU' &&
        getters.hasSavedAddress &&
        getters.isTaxationFormAboutToExpire
      ) {
        alerts.push(accountAlerts.TAX_FORM_EXPIRES);
      }

      // TAXATION NA ALERTS
      if (
        SPRD.PLATFORM === 'NA' &&
        getters.hasSavedAddress &&
        rootGetters['userTrolley/addressMismatch']
      ) {
        alerts.push(accountAlerts.TROLLEY_ADDRESS_MISMATCH);
      }

      const statisticsStore = useStatisticsStore();

      if (SPRD.PLATFORM === 'NA' && statisticsStore.lifetimeSales > 0) {
        if (rootGetters['userTrolley/paymentDataMissing']) {
          alerts.push(accountAlerts.TROLLEY_PAYMENT_DATA_MISSING);
        }

        if (
          getters.hasSavedAddress &&
          rootGetters['userTrolley/taxationMissing']
        ) {
          alerts.push(accountAlerts.TROLLEY_TAX_FORM_MISSING);
        }

        if (
          getters.hasSavedAddress &&
          rootGetters['userTrolley/taxationInvalid']
        ) {
          alerts.push(accountAlerts.TROLLEY_TAX_FORM_INVALID);
        }
      }

      // MFA ALERT
      if (rootState.user.mfaStatus.key === MFA_STATES.SETUP.key) {
        alerts.push(accountAlerts.MFA_MISSING);
      }

      return alerts;
    },
    hasSavedAddress: (state, getters, rootState) => {
      return (
        rootState.userAddress.originalData.city &&
        rootState.userAddress.originalData.street &&
        rootState.userAddress.originalData.country?.code
      );
    },
    isAdmin: (state) => {
      return state.isAdmin;
    },
    isTaxationFormAboutToExpire: (state, getters, rootState) => {
      return (
        SPRD.PLATFORM === 'NA' &&
        rootState.userTaxation.isTaxationFormAboutToExpire
      );
    },
    taxationFormValid: (state, getters, rootState) => {
      if (SPRD.PLATFORM === 'EU') {
        return true;
      }

      return rootState.userTaxation.taxationValid;
    },
  },
  actions: {
    async fetchAccountData({ commit }) {
      const data = await accountService.getData();
      commit('setIsAdmin', data.origin.session.isAdmin);
    },
  },
  mutations: {
    setIsAdmin(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    setPayoutLimitReached(state, value) {
      state.payoutLimitReached = value;
    },
  },
};
