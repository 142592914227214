import userCoreService from '@/api/userAccount/userCoreService';
import { isEqual, deepCopy, isEmpty } from '@/utils';
import taxationDataValidator from '@/api/validators/userAccount/taxationDataValidator';

export default {
  namespaced: true,
  state: {
    data: null,
    originalData: null,
    countries: null,
    types: null,
    isTaxationFormAboutToExpire: null,
    taxationValid: null,
    obfuscatedData: null,
    originalObfuscatedData: null,
    validate: false,
    validation: {
      errors: {},
      result: true,
    },
  },
  getters: {
    hasChanged: (state) =>
      !isEqual(state.data, state.originalData) ||
      !isEqual(state.obfuscatedData, state.originalObfuscatedData),
    dataWithObfuscatedFields: (state) =>
      getDataWithObfuscatedFields(state.data, state.obfuscatedData),
    currentTaxCountry: (state) =>
      state.countries.find((c) => c.countryCode === state.data.country),
    taxationConsistent: (state) =>
      state.types?.find((type) => type.name === state.data.type)?.applicable,
    taxationValid: (state, getters) => {
      return (
        getters.taxationConsistent &&
        taxationDataValidator.singleValidate.taxId(
          getters.dataWithObfuscatedFields,
          state.obfuscatedData,
        ).result &&
        taxationDataValidator.singleValidate.ssn(
          getters.dataWithObfuscatedFields,
          state.obfuscatedData,
        ).result
      );
    },
  },
  mutations: {
    setData(
      state,
      { data, countries, types, isTaxationFormAboutToExpire, taxationValid },
    ) {
      state.obfuscatedData = deepCopy(data);
      state.originalObfuscatedData = deepCopy(state.obfuscatedData);

      state.data = removeObfuscatedData(data);
      state.originalData = deepCopy(state.data);

      state.countries = countries;
      state.types = types;

      state.isTaxationFormAboutToExpire = isTaxationFormAboutToExpire;
      state.taxationValid = taxationValid;
    },
    setValidate(state, validate) {
      state.validate = validate;
    },
    reset(state) {
      state.data = deepCopy(state.originalData);
      state.validation = { errors: {}, result: true };
    },
    setTaxationType(state, { taxationType }) {
      state.data.type = taxationType;
    },
    setTaxId(state, { taxId }) {
      state.data.taxId = taxId;
      validateField(state, 'taxId');
    },
    setVatId(state, { vatId }) {
      state.data.vatId = vatId;
      validateField(state, 'vatId');
    },
    setTaxCountry(state, { countryCode }) {
      state.data.country = countryCode;
    },
    setSsn(state, { ssn }) {
      state.data.ssn = ssn;
      validateField(state, 'ssn');
    },
    validate(state) {
      state.validation = taxationDataValidator.validate(
        state.data,
        state.obfuscatedData,
      );
    },
    setSavingError(state) {
      state.validation.error = true;
    },
    clearObfuscatedField(state, { field }) {
      state.obfuscatedData[field] = null;
    },
  },
  actions: {
    async fetchTaxationData({ commit }) {
      const data = await userCoreService.getTaxationData();
      commit('setData', data);
    },
    saveTaxationData({ commit, state, getters }) {
      return new Promise((resolve, reject) => {
        commit('setValidate', true);
        commit('validate');
        if (state.validation.result) {
          const dataToSave = getters.dataWithObfuscatedFields;
          userCoreService.updateTaxationData(dataToSave).then(
            (data) => {
              commit('setData', data);
              resolve(data);
            },
            (error) => {
              commit('setSavingError', error);
              reject(error);
            },
          );
        } else {
          reject();
        }
      });
    },
  },
};

function validateField(state, field) {
  if (!state.validate) {
    return;
  }

  resetValidationErrorForField(state.validation, field);
  const validationResult = taxationDataValidator.validateByFieldName(
    field,
    state.data,
    state.obfuscatedData,
  );
  state.validation = taxationDataValidator.mergeResults(
    state.validation,
    validationResult,
  );
}

function resetValidationErrorForField(validation, field) {
  if (validation.errors[field]) {
    delete validation.errors[field];
  }
  return validation;
}

function removeObfuscatedData(data) {
  const newData = deepCopy(data);

  if (!isEmpty(newData.taxId)) {
    newData.taxId = '';
  }

  if (!isEmpty(newData.vatId)) {
    newData.vatId = '';
  }

  if (!isEmpty(newData.ssn)) {
    newData.ssn = '';
  }

  return newData;
}

function getDataWithObfuscatedFields(data, obfuscatedData) {
  if (!data || !obfuscatedData) {
    return null;
  }

  const result = JSON.parse(JSON.stringify(data));
  if (isEmpty(result.taxId) && obfuscatedData.taxId) {
    result.taxId = obfuscatedData.taxId;
  }
  if (isEmpty(result.vatId) && obfuscatedData.vatId) {
    result.vatId = obfuscatedData.vatId;
  }
  if (isEmpty(result.ssn) && obfuscatedData.ssn) {
    result.ssn = obfuscatedData.ssn;
  }

  return result;
}
