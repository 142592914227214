import localeService from 'src/app/commons/localeService/localeService';
import { Base64 } from 'js-base64';
import apiClient from '@/api/client/apiClient';
import store from '@/store/Store';

const accountService = {
  async getData() {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `/api/v1/users/${store.state.user.data.id}/account`,
      params: {
        locale: localeService.convertToSprdLocale(localeService.getLocale()),
      },
    });
    return data;
  },
  async deleteAccount(password) {
    const config = {
      params: {
        locale: localeService.convertToSprdLocale(localeService.getLocale()),
      },
      headers: {
        'password-base64': Base64.encode(password),
        'Content-Type': 'application/json',
      },
      data: {},
    };

    await apiClient.request({
      method: 'DELETE',
      url: `rest/user/${store.state.user.data.id}/account`,
      ...config,
    });
    return store.dispatch('user/logout');
  },
};

export default accountService;
