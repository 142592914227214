<template>
  <Banner
    v-if="publishingLimitReached"
    class="banner"
    icon="error"
    heading="DMS.PUBLISHING_LIMIT_REACHED.BANNER.HEADING"
    subheading="DMS.PUBLISHING_LIMIT_REACHED.BANNER.DESCRIPTION"
    link="DMS.PUBLISHING_LIMIT_REACHED.BANNER.LINK"
    warning
    @on-link-click="navigateToPubishedMpDesigns"
  />
</template>

<script setup lang="ts">
import Banner from 'src/app/components/banners/Banner.vue';
import router from 'src/app/router/router';
import { useStore } from 'vuex';

const store = useStore();
const publishingLimitReached = store.getters['dms/publishingLimitReached'];

const navigateToPubishedMpDesigns = () => {
  router.push({
    name: 'partnerarea.mp.publishing',
  });
};
</script>
