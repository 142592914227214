import apiClient from '@/api/client/apiClient';
import {
  DMS_PROPERTIES,
  DMS_SORTED_BENEFIT_LIST,
  DMS_GROUPS,
} from '@/api/models/dms/dmsStates';
import ideaHelper from '@/ideaHelper/ideaHelper';
import dialogService from '@/dialogs/wrapper/dialogService';
import DmsGroupChangeDialog from 'src/app/components/dms/DmsGroupChangeDialog.vue';

const propertyMapper = (state) => (property) => ({
  ...property,
  value: property.value(state.currentGroupData),
  meta: property.meta(state.currentGroupData),
});

export default {
  namespaced: true,
  state: {
    currentGroupData: {},
    publishingCounter: null,
    pollingInterval: null,
  },
  getters: {
    propertyList: (state) =>
      Object.values(DMS_PROPERTIES).map(propertyMapper(state)),
    benefitList: (state) => DMS_SORTED_BENEFIT_LIST.map(propertyMapper(state)),
    getProperty: (state, getters) => (property) =>
      getters.propertyList.find((entry) => entry.key === property.key),
    currentGroup: (state) => DMS_GROUPS[state.currentGroupData.designerGroup],
    publishingCount: (state) => state.publishingCounter,
    translationsEditable: (state, getters, rootState, rootGetters) =>
      getters.getProperty(DMS_PROPERTIES.INT_PUB).value &&
      !rootGetters['user/isNonInternationalShopOnlyUser'],
    livePublishingCount: (state, getters, rootState) => {
      const currentIdeasListMpPublishCount = rootState.ideas.list.filter(
        (idea) => ideaHelper.isPublishedOnMarketplace(idea),
      ).length;
      const originalIdeasListMpPublishCount =
        rootState.ideas.originalList.filter((idea) =>
          ideaHelper.isPublishedOnMarketplace(idea),
        ).length;
      const currentIdeaMpPublish =
        rootState.idea.current &&
        ideaHelper.isPublishedOnMarketplace(rootState.idea.current)
          ? 1
          : 0;
      const originalIdeaMpPublish =
        rootState.idea.original &&
        ideaHelper.isPublishedOnMarketplace(rootState.idea.original)
          ? 1
          : 0;
      return (
        getters.publishingCount +
        (currentIdeasListMpPublishCount - originalIdeasListMpPublishCount) +
        (currentIdeaMpPublish - originalIdeaMpPublish)
      );
    },
    publishingLimitReached: (state, getters) =>
      getters.getProperty(DMS_PROPERTIES.PUBLISHING_LIMIT).value &&
      getters.getProperty(DMS_PROPERTIES.PUBLISHING_LIMIT).value <=
        getters.livePublishingCount, // TODO: use new global counter instead of getters.publishingCount
    publishingLimitExceeded: (state, getters) =>
      getters.getProperty(DMS_PROPERTIES.PUBLISHING_LIMIT).value &&
      getters.getProperty(DMS_PROPERTIES.PUBLISHING_LIMIT).value <
        getters.livePublishingCount, // TODO: use new global counter instead of getters.publishingCount
  },
  mutations: {
    setCurrentGroupData(state, currentGroupData) {
      state.currentGroupData = currentGroupData;
    },
    setPublishingCounter(state, publishingCounter) {
      state.publishingCounter = publishingCounter;
    },
    setPollingInterval(state, pollingInterval) {
      state.pollingInterval = pollingInterval;
    },
  },
  actions: {
    async fetchPartnerData({ commit, dispatch, rootState, rootGetters }) {
      const { data } = await apiClient.request({
        method: 'GET',
        url: `/api/v1/users/${rootState.user.data.id}/partnerData`,
      });
      commit('setCurrentGroupData', data.designerProperties);
      commit('setPublishingCounter', data.publishingLimitCounter);

      if (
        rootGetters['settings/getSetting']('PREVIOUS_DESIGNER_GROUP') !==
        data.designerProperties.designerGroup
      ) {
        if (
          rootGetters['settings/getSetting']('PREVIOUS_DESIGNER_GROUP') &&
          rootGetters['user/hasMarketplace']
        ) {
          dialogService.openDialog(DmsGroupChangeDialog);
        }

        if (!rootGetters['account/isAdmin']) {
          dispatch(
            'settings/setSetting',
            {
              key: 'PREVIOUS_DESIGNER_GROUP',
              value: data.designerProperties.designerGroup,
            },
            { root: true },
          );
        }
      }
    },
    async startPolling({ commit, state, dispatch }) {
      if (state.pollingInterval) {
        return;
      }

      commit(
        'setPollingInterval',
        setInterval(
          () => {
            dispatch('fetchPartnerData');
          },
          10 * 60 * 1000,
        ),
      );
    },
    async stopPolling({ commit, state }) {
      clearInterval(state.pollingInterval);

      commit('setPollingInterval', null);
    },
  },
};
