import store from '@/store/Store.js';
import { mergeResults, validateStringAttribute } from '../genericValidator';

const accountValidator = {
  validate(data) {
    return Object.keys(accountValidator.singleValidate)
      .map((field) => accountValidator.singleValidate[field](data))
      .reduce((previous, current) => mergeResults(previous, current), {});
  },
  singleValidate: {
    firstName: function (data) {
      return validateStringAttribute('firstName', data.person.firstName, {
        required: true,
      });
    },
    lastName: function (data) {
      return validateStringAttribute('lastName', data.person.lastName, {
        required: true,
      });
    },
    company: function (data) {
      if (typeof data.company === 'string') {
        return validateStringAttribute('company', data.company, {
          required: true,
        });
      } else {
        return {
          result: true,
        };
      }
    },
    country: function (data) {
      return validateStringAttribute('country', data.country?.code, {
        required: true,
      });
    },
    city: function (data) {
      return validateStringAttribute('city', data.city, {
        required: true,
        maxLength: 30,
      });
    },
    postalCode: function (data) {
      return validateStringAttribute('postalCode', data.zipCode, {
        required: true,
      });
    },
    street: function (data) {
      return validateStringAttribute('street', data.originalStreet, {
        required: true,
        maxLength: 50,
      });
    },
    street2: function (data) {
      return validateStringAttribute('street2', data.streetAnnex, {
        maxLength: 50,
      });
    },
    state: function (data) {
      const states =
        store.state.userAddress.countries.find(
          (country) => country.countryCode === data.country.code,
        )?.states || [];
      if (states?.length) {
        return validateStringAttribute('state', data.state?.code, {
          required: true,
        });
      } else {
        return {
          result: true,
        };
      }
    },
  },
  validateByFieldName(fieldName, data) {
    if (accountValidator.singleValidate[fieldName]) {
      return accountValidator.singleValidate[fieldName](data);
    } else {
      return {};
    }
  },
  validateMultipleFieldNames(fieldNames, data) {
    if (!fieldNames || !fieldNames.length) {
      return {
        result: true,
        errors: {},
      };
    }

    return fieldNames
      .map((fieldName) => accountValidator.validateByFieldName(fieldName, data))
      .reduce((previous, current) => {
        return mergeResults(previous, current);
      }, {});
  },
  mergeResults,
};

export default accountValidator;
