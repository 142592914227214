<template>
  <p class="dms-design-checklist-counter">
    <span class="design-checklist">{{ count }} </span>
    /{{ dmsChecklist.value }}
  </p>
</template>

<script>
import { DMS_PROPERTIES } from '@/api/models/dms/dmsStates';
import { mapGetters } from 'vuex';

export default {
  name: 'DmsChecklistCounter',
  computed: {
    ...mapGetters({
      getProperty: 'dms/getProperty',
      publishingCount: 'dms/livePublishingCount',
    }),
    count() {
      return Math.min(10, this.publishingCount);
    },
    dmsChecklist() {
      return this.getProperty(DMS_PROPERTIES.DESIGN_CHECKLIST);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.dms-design-checklist-counter {
  margin: 0;
  display: inline-flex;
  align-items: center;

  .icon {
    height: 18px;
    width: 18px;
  }
}

.design-checklist {
  font-weight: bold;

  &.limit-exceeded {
    color: $pa-color-red;
  }
}
</style>
