<template>
  <div>
    <p>{{ $t('ACCOUNT.TAXATION.EU_PRIVATE.HEADING') }}</p>
    <div v-if="userAddress.country.code !== 'DE'" class="taxation-data">
      <div class="taxation-data-column">
        <p>
          {{ $t('ACCOUNT.TAXATION.EU_PRIVATE.TAX_ID_EXPLANATION1') }}
        </p>
        <p>
          {{ $t('ACCOUNT.TAXATION.EU_PRIVATE.TAX_ID_EXPLANATION2') }}
        </p>
        <p>
          {{ $t('ACCOUNT.TAXATION.EU_NO_EU.UMSATZSTEUER_TOOLTIP_EXPLANATION') }}
        </p>
        <SmartFaqLink :link="taxationFAQLink" />
        <div class="tax-id" :class="{ 'has-error': validation.errors.taxId }">
          <LabelInput>
            <label for="tax-id">{{
              $t('ACCOUNT.TAXATION.EU_PRIVATE.TAX_ID')
            }}</label>
            <div class="position-relative">
              <DebounceInput
                id="tax-id"
                type="text"
                :model-value="taxationData.taxId"
                :placeholder="obfuscatedData.taxId"
                @update:model-value="setTaxId"
              />
              <a
                v-if="!taxationData.taxId && obfuscatedData.taxId"
                href
                class="input-clear-button"
                @click.prevent="clearObfuscatedField({ field: 'taxId' })"
              >
                <Icon icon="close"></Icon>
              </a>
            </div>
            <AccountError
              v-if="validation.errors.taxId"
              :reason="validation.errors.taxId.reason"
            ></AccountError>
          </LabelInput>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import smartFaqLinks from '@/smartFaq/smartFaqLinks';
import LabelInput from '@/labelInput/LabelInput.vue';
import AccountError from './../../errorMessage/AccountError.vue';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import DebounceInput from 'src/app/components/input/DebounceInput.vue';

export default {
  name: 'PrivateEU',
  components: {
    LabelInput,
    AccountError,
    SmartFaqLink,
    DebounceInput,
  },
  props: {
    validation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      taxationFAQLink: smartFaqLinks.WITHHOLDING_TAX_INFO,
    };
  },
  computed: {
    ...mapState({
      userAddress: (state) => state.userAddress.data,
      taxationData: (state) => state.userTaxation.data,
      obfuscatedData: (state) => state.userTaxation.obfuscatedData,
    }),
  },
  methods: {
    ...mapMutations({
      updateTaxId: 'userTaxation/setTaxId',
      clearObfuscatedField: 'userTaxation/clearObfuscatedField',
    }),
    setTaxId(taxId) {
      this.updateTaxId({ taxId });
    },
  },
};
</script>

<style lang="scss" scoped>
.tax-id {
  display: flex;
  margin-top: 30px;
}

.tax-id .labelInput {
  width: 250px;
}

.taxation-data {
  display: flex;

  .taxation-data-column {
    flex: 7;
    padding-right: 10%;
  }

  .info-box {
    flex: 3;
  }
}
</style>
