<template>
  <div class="publishing-meta-data">
    <button
      v-tooltip.left-start="{
        content: 'PUBLISHING.DETAIL.META_DATA.DEFAULT_LANGUAGE.HINT',
      }"
      class="link language-link"
      @click="openDefaultTranslationDialog"
    >
      <Icon icon="globe" class="globe" /> {{ languageName }}
      <Icon icon="down" />
    </button>

    <div class="meta-data-ai">
      <div
        v-tooltip.right-start="{
          content: !aiMetaDataGenerationAvailable
            ? 'PUBLISHING.DETAIL.META_DATA.AI.LIMIT'
            : null,
        }"
      >
        <AiButton
          :disabled="aiGenerationLoading || !aiMetaDataGenerationAvailable"
          @ai-button-clicked="generateMetaData"
        >
          {{
            $t(
              aiGenerationLoading
                ? 'PUBLISHING.DETAIL.META_DATA.AI.GENERATING'
                : 'PUBLISHING.DETAIL.META_DATA.AI.GENERATE_CTA',
            )
          }}
        </AiButton>
      </div>

      <div class="legal">
        <p v-html="$t('AI_DESIGN_GENERATOR.PROMPT.LEGAL')"></p>
      </div>
    </div>

    <div class="meta-data-content">
      <div>
        <LabelInput
          :key="aiGenerationLoading"
          :class="{ 'has-error': nameError }"
        >
          <label for="name">
            {{ $t('PUBLISHING.DETAIL.META_DATA.NAME.LABEL') }}
          </label>
          <AiContainer :disable-effect="!aiGenerationLoading">
            <DebounceInput
              id="name"
              type="text"
              :model-value="metaData.name.value"
              :maxlength="validatorOptions.name.max"
              @update:model-value="changeName"
              @change="
                (evt) => checkBlacklistForField('name', evt.target.value)
              "
            />
          </AiContainer>
          <div class="field-info">
            <small v-if="!validName" class="error-info error-info-name">
              {{
                $t('DESIGNS.VALIDATION.NAME.LENGTH', {
                  min: validatorOptions.name.min,
                  max: validatorOptions.name.max,
                })
              }}
            </small>
            <small
              v-else-if="blacklist.name.length"
              class="error-info error-info-name"
            >
              {{ $t('DESIGNS.VALIDATION.BLACKLIST.TERMS') }}:
              {{ blacklist.name.join(', ') }}
            </small>
            <small v-else class="info">
              {{ $t('PUBLISHING.DETAIL.META_DATA.NAME.DESCRIPTION') }}
            </small>

            <small class="char-info design-description-chars-left"
              >{{ metaData.name.value.length || 0 }} /
              {{ validatorOptions.name.max }}</small
            >
          </div>
        </LabelInput>
      </div>
      <div>
        <LabelInput
          :key="aiGenerationLoading"
          :class="{
            'has-error': descriptionError,
          }"
        >
          <label for="description">{{
            $t('PUBLISHING.DETAIL.META_DATA.DESCRIPTION.LABEL')
          }}</label>
          <AiContainer :disable-effect="!aiGenerationLoading">
            <DebounceTextarea
              name="description"
              :maxlength="200"
              :model-value="metaData.description.value"
              rows="3"
              spellcheck="true"
              @update:model-value="changeDescription"
              @change="
                (evt) => checkBlacklistForField('description', evt.target.value)
              "
            />
          </AiContainer>
          <div class="field-info">
            <small
              v-if="blacklist.description.length"
              class="error-info error-info-description"
            >
              {{ $t('DESIGNS.VALIDATION.BLACKLIST.TERMS') }}:
              {{ blacklist.description.join(', ') }}
            </small>
            <small v-else class="info">
              {{ $t('PUBLISHING.DETAIL.META_DATA.DESCRIPTION.DESCRIPTION') }}
            </small>
            <small class="char-info design-description-chars-left"
              >{{ metaData.description.value.length || 0 }} /
              {{ validatorOptions.description.max }}</small
            >
          </div>
        </LabelInput>
      </div>
      <div>
        <CompositionTagInput
          ref="tagInput"
          :tags="metaData.tags.values"
          :composition="composition"
          :display-tags-left="true"
          :display-related-tags="false"
          :display-error-text="true"
          :language="currentLocale"
          :display-hint="true"
          :validate="validate"
          label-text="PUBLISHING.DETAIL.META_DATA.TAGS.LABEL"
          hint-text="PUBLISHING.DETAIL.META_DATA.TAGS.DESCRIPTION"
          :ai-effect="aiGenerationLoading"
          @add-tag="onAddTag"
          @remove-tag="onRemoveTag"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import LabelInput from '@/labelInput/LabelInput.vue';
import compositionHelper from '@/composition/compositionHelper';
import CompositionTagInput from '@/tagInput/CompositionTagInput.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import PublishingDefaultTranslationDialog from './PublishingDefaultTranslationDialog.vue';
import compositionValidator from '@/api/validators/composition/compositionValidator';
import { checkTerm } from '@/api/blacklistService/blacklistService';
import DebounceInput from 'src/app/components/input/DebounceInput.vue';
import DebounceTextarea from 'src/app/components/input/DebounceTextarea.vue';
import AiButton from 'src/app/components/ai/AiButton.vue';
import AiContainer from 'src/app/components/ai/AiContainer.vue';
import { delay } from '@/utils';
import aiService from '@/api/ai/aiService';

export default {
  name: 'PublishingMetaData',
  components: {
    LabelInput,
    CompositionTagInput,
    DebounceInput,
    DebounceTextarea,
    AiButton,
    AiContainer,
  },
  props: {
    validate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      validatorOptions: compositionValidator.getDefaultOptions(),
      validation: {
        errors: {},
      },
      blacklist: {
        name: [],
        description: [],
      },
      aiGenerationLoading: false,
    };
  },
  computed: {
    ...mapState({
      composition: (s) => s.composition.current,
    }),
    ...mapGetters({
      languageByIso: 'platform/languageByIso',
    }),
    metaData() {
      return compositionHelper.getDefaultMetaData(this.composition);
    },
    currentLocale() {
      return this.composition.metaData.originLanguageCode;
    },
    languageName() {
      return this.languageByIso(this.composition.metaData.originLanguageCode)
        ?.name;
    },
    validName() {
      if (!this.validate) {
        return true;
      }

      return compositionValidator.validateName(this.composition, {
        locale: this.currentLocale,
      });
    },
    nameError() {
      return !this.validName || this.blacklist.name.length;
    },
    validDescription() {
      if (!this.validate) {
        return true;
      }

      return compositionValidator.validateDescription(this.composition, {
        locale: this.currentLocale,
      });
    },
    descriptionError() {
      return !this.validDescription || this.blacklist.description.length;
    },
    hasError() {
      return (
        this.nameError || this.descriptionError || this.$refs.tagInput.hasError
      );
    },
    aiMetaDataGenerationAvailable() {
      return (
        this.composition.attributes?.AI_META_DATA !==
        this.composition.metaData.originLanguageCode
      );
    },
  },
  created() {
    this.checkBlacklistForField('name', this.metaData.name.value);
    this.checkBlacklistForField('description', this.metaData.description.value);
  },
  methods: {
    ...mapMutations({
      setName: 'composition/setName',
      setDescription: 'composition/setDescription',
      addTag: 'composition/addTag',
      removeTag: 'composition/removeTag',
      setTags: 'composition/setTags',
      editAttributes: 'composition/editAttributes',
    }),
    ...mapActions({
      updateComposition: 'composition/updateComposition',
    }),
    changeName(name) {
      this.setName({ name });
    },
    changeDescription(description) {
      this.setDescription({ description });
    },
    onAddTag(tag) {
      this.addTag({ tag });
    },
    onRemoveTag(tag) {
      this.removeTag({ tag });
    },
    openDefaultTranslationDialog() {
      dialogService.openDialog(PublishingDefaultTranslationDialog);
    },
    async checkBlacklistForField(field, terms) {
      const locale = this.currentLocale;

      try {
        await checkTerm({
          field,
          terms,
          locale,
        });
        this.blacklist[field] = [];
      } catch (error) {
        if (error?.data?.list) {
          this.blacklist[field] = error.data.list[0].blacklisted.reduce(
            (result, blacklist) => result.concat(blacklist.terms),
            [],
          );
        }
      }
    },
    async generateMetaData() {
      this.aiGenerationLoading = true;
      const metaData = await aiService.generateMetaData({
        language: this.composition.metaData.originLanguageCode,
        imageUrl: `${compositionHelper.getDesignImage(this.composition)},width=1200,height=1200.png`,
      });
      await delay(1000);
      this.setName({ name: metaData.name });
      this.setDescription({ description: metaData.description });
      this.setTags({ tags: metaData.tags });
      this.aiGenerationLoading = false;

      this.editAttributes({
        AI_META_DATA: this.composition.metaData.originLanguageCode,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.meta-data-content {
  width: 100%;

  & > div {
    padding: 16px 0 8px 0;
  }

  :deep(.ai-container) {
    width: 100%;
    background-color: $grey5;
    pointer-events: none;
    & > input,
    & > textarea {
      background-color: $grey5;
      color: $grey5;
    }
    .tag-input-container {
      width: 100%;
      background-color: $grey5;

      * {
        visibility: hidden;
      }
    }
  }

  :deep(label:has(+ .ai-container)) {
    color: $grey50;
    top: 12px !important;

    &::before {
      background-color: $grey5 !important;
    }
  }
}

.meta-data-ai {
  & > div {
    display: inline-block;
  }

  :deep(button) {
    padding: 8px 12px;
  }

  .legal {
    display: flex;
    margin: 8px 0;

    p {
      @extend .text-xs;
      margin: 0;
      color: $grey60;
    }

    :deep(a) {
      text-decoration: underline;
      color: $grey60;

      &:hover {
        color: $grey65;
      }
    }
  }
}

.language-link {
  position: absolute;
  top: 24px;
  right: 24px;
  font-weight: normal;
  color: $grey80;
  display: flex;
  align-items: center;
  gap: 8px;

  .icon {
    height: 16px;
    width: 16px;

    &.globe {
      height: 20px;
      width: 20px;
    }
  }
}
</style>
