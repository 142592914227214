<template>
  <DetailPage v-if="initialized" heading="PUBLISHING.DETAIL.HEADING">
    <template #left-nav>
      <button class="link link-main icon-link" @click="back">
        <Icon icon="left" /> {{ $t('GENERAL.BACK') }}
      </button>
    </template>
    <template #right-nav>
      <a v-if="pubCLink" class="btn btn-light" :href="pubCLink" target="_blank">
        <Icon icon="outside" /> PubC
      </a>
      <a
        v-if="!hasMultipleDesigns"
        class="btn link icon-link download-icon"
        download
        target="_self"
        :href="designDownloadLink"
      >
        <Icon icon="savedisk" />
      </a>
      <button
        class="btn btn-light-danger delete-btn"
        @click="triggerDeleteComposition"
      >
        <Icon icon="bin" />
      </button>
      <VDropdown>
        <button href="#" class="btn btn-light info-btn" @click.prevent>
          <Icon icon="info-circle" />
        </button>
        <template #popper>
          <CompositionInfos :composition="composition" />
        </template>
      </VDropdown>

      <ProgressButton
        class="btn btn-primary save-btn"
        :on-click="save"
        :label="saveLabel"
        :disabled="!dirty"
      />
    </template>
    <template #content>
      <div>
        <div class="publishing-detail-content">
          <div class="configuration-menu">
            <div class="configuration-menu-content">
              <PublishingConfigMenuItem
                v-if="displayShop"
                :target-ref="refsReady ? $refs.publishShop : null"
              >
                {{ $t('PUBLISHING.DETAIL.SHOP.HEADING') }}
              </PublishingConfigMenuItem>
              <PublishingConfigMenuItem
                v-if="displayMarketplace"
                :target-ref="refsReady ? $refs.publishMp : null"
              >
                {{ $t('PUBLISHING.DETAIL.MP.HEADING') }}
              </PublishingConfigMenuItem>
              <PublishingConfigMenuItem
                :target-ref="refsReady ? $refs.details : null"
                :error="$refs.metaData && $refs.metaData.hasError"
              >
                {{ $t('PUBLISHING.DETAIL.META_DATA.HEADING') }}
              </PublishingConfigMenuItem>
              <PublishingConfigMenuItem
                :target-ref="refsReady ? $refs.products : null"
              >
                {{ $t('PUBLISHING.DETAIL.PRODUCTS.HEADING') }}
              </PublishingConfigMenuItem>
            </div>
          </div>
          <div class="configuration">
            <div>
              <ConfigSection
                heading="PUBLISHING.DETAIL.PRODUCTS.HEADING"
                subheading="PUBLISHING.DETAIL.PRODUCTS.SUBHEADING"
              >
                <InlineOnboarding
                  text="PUBLISHING.DETAIL.PRODUCTS.ONBOARDING"
                  :condensed="hasIntentForCurrentScope"
                  :condensed-style="inlineOnboardingCondensedStyle"
                  class="onboarding-container"
                />
                <PublishingProducts />
              </ConfigSection>
            </div>
            <div>
              <div ref="products" class="scroll-target"></div>

              <ConfigSection
                heading="PUBLISHING.DETAIL.META_DATA.HEADING"
                subheading="PUBLISHING.DETAIL.META_DATA.SUBHEADING"
              >
                <InlineOnboarding
                  text="PUBLISHING.DETAIL.META_DATA.ONBOARDING"
                  :condensed="hasIntentForCurrentScope"
                  :condensed-style="inlineOnboardingCondensedStyle"
                  class="onboarding-container"
                />
                <PublishingMetaData
                  ref="metaData"
                  :validate="strictValidation && shouldValidate"
                />
              </ConfigSection>
              <div ref="details" class="scroll-target"></div>
            </div>
            <div v-if="displayMarketplace">
              <ConfigSection
                heading="PUBLISHING.DETAIL.MP.HEADING"
                subheading="PUBLISHING.DETAIL.MP.SUBHEADING"
              >
                <InlineOnboarding
                  text="PUBLISHING.DETAIL.MP.ONBOARDING"
                  :condensed="hasIntentForCurrentScope"
                  :condensed-style="inlineOnboardingCondensedStyle"
                  class="onboarding-container"
                />
                <PublishingMpConfig />
              </ConfigSection>
            </div>
            <div ref="publishMp" class="scroll-target"></div>
            <div v-if="displayShop">
              <ConfigSection
                heading="PUBLISHING.DETAIL.SHOP.HEADING"
                subheading="PUBLISHING.DETAIL.SHOP.SUBHEADING"
              >
                <InlineOnboarding
                  text="PUBLISHING.DETAIL.SHOP.ONBOARDING"
                  :condensed="hasIntentForCurrentScope"
                  :condensed-style="inlineOnboardingCondensedStyle"
                  class="onboarding-container"
                />
                <PublishingShopConfig />
              </ConfigSection>
            </div>
            <div ref="publishShop" class="scroll-target"></div>
          </div>
        </div>
      </div>
    </template>
  </DetailPage>
  <div v-else class="loader">
    <LoadingHeart />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LoadingHeart from '@/LoadingHeart/LoadingHeart.vue';
import ProgressButton from 'src/app/commons/btnProgress/ProgressButton.vue';
import ConfigSection from 'src/app/components/publishing/ConfigSection.vue';
import PublishingMpConfig from './PublishingMpConfig.vue';
import PublishingShopConfig from './PublishingShopConfig.vue';
import PublishingProducts from './PublishingProducts.vue';
import PublishingMetaData from './PublishingMetaData.vue';
import PublishingConfigMenuItem from './PublishingConfigMenuItem.vue';
import CompositionInfos from './CompositionInfos.vue';
import ConfirmDialog from '@/dialogs/confirm/ConfirmDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import unsavedChangesService from '@/unsavedChangesService/unsavedChangesService';
import DetailPage from 'src/app/components/detailPage/DetailPage.vue';
import InlineOnboarding from 'src/app/components/onboarding/InlineOnboarding.vue';
import PdvDialog from '@/dialogs/address/PdvDialog.vue';

export default {
  name: 'PublishingDetail',
  components: {
    LoadingHeart,
    ProgressButton,
    ConfigSection,
    PublishingMpConfig,
    PublishingShopConfig,
    PublishingProducts,
    PublishingMetaData,
    PublishingConfigMenuItem,
    CompositionInfos,
    DetailPage,
    InlineOnboarding,
  },
  data() {
    return {
      initialized: false,
      displayMenu: true,
      refsReady: false,
      strictValidation: undefined,
      inlineOnboardingCondensedStyle: {
        'margin-top': '-16px',
      },
    };
  },
  computed: {
    ...mapState({
      composition: (s) => s.composition.current,
      currentScope: (s) => s.publishing.currentScope,
    }),
    ...mapGetters({
      dirty: 'composition/dirty',
      isPublic: 'composition/isPublic',
      shouldValidate: 'composition/shouldValidate',
      hasMultipleDesigns: 'composition/hasMultipleDesigns',
      designDownloadLink: 'composition/designDownloadLink',
      isAdmin: 'account/isAdmin',
      hasMarketplace: 'user/hasMarketplace',
      hasRegisteredShops: 'publishingCore/hasRegisteredShops',
      isMpScope: 'publishing/isMpScope',
      isShopScope: 'publishing/isShopScope',
      hasIntentForCurrentScope: 'publishingCore/hasIntentForCurrentScope',
      hasPublishRequirements: 'user/hasPublishRequirements',
    }),
    displayMarketplace() {
      return this.isMpScope && this.hasMarketplace;
    },
    displayShop() {
      return this.isShopScope && this.hasRegisteredShops;
    },
    saveLabel() {
      return this.isPublic ? 'GENERAL.SAVE_AND_PUBLISH' : 'GENERAL.SAVE';
    },
    pubCLink() {
      if (!this.isAdmin) {
        return null;
      }

      return `https://pubc-insight-${
        SPRD.PLATFORM === 'NA' ? 'na' : 'eu'
      }.sprd.net/compositions?compositionId=${this.composition.id}`;
    },
  },
  async created() {
    await this.$store.state.publishingCore.onDataLoaded;

    await this.fetchComposition(this.$route.params.compositionId);
    this.ensureCompositionScope();
    this.initialized = true;

    setTimeout(() => {
      this.refsReady = true;
    }, 200);

    unsavedChangesService.registerComponent({
      check: () => this.dirty,
      save: () => {
        return this.save();
      },
      discard: () => {
        this.resetToOriginal();
      },
    });
  },
  methods: {
    ...mapActions({
      fetchComposition: 'composition/fetchComposition',
      updateComposition: 'composition/updateComposition',
      deleteComposition: 'compositions/deleteComposition',
    }),
    ...mapMutations({
      resetToOriginal: 'composition/resetToOriginal',
    }),
    async triggerDeleteComposition() {
      try {
        await dialogService.openDialog(ConfirmDialog, {
          heading: 'DESIGNS.DELETE.SINGLE_WARNING',
          cancel: 'GENERAL.CANCEL',
          confirm: 'GENERAL.DELETE',
          danger: true,
        });
        await this.deleteComposition(this.composition.id);
        this.$router.push({
          name: this.currentScope.routes.listPage,
          query: { restoreState: true },
        });
      } catch (_) {
        // nothing
      }
    },
    back() {
      this.$router.push({
        name: this.currentScope.routes.listPage,
        query: { restoreState: true },
      });
    },
    async save() {
      if (this.isPublic && !this.hasPublishRequirements) {
        try {
          await dialogService.openDialog(PdvDialog);
        } catch (error) {
          return Promise.reject(error);
        }
      }

      this.strictValidation = true;
      await this.updateComposition();
      this.strictValidation = undefined;
    },
    ensureCompositionScope() {
      if (!this.composition?.scopes?.includes(this.currentScope?.key)) {
        this.$router.push({
          name: this.currentScope.routes.listPage,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.download-icon {
  border-color: $pa-color-green;
  .icon {
    width: 14px;
    height: 14px;
    color: $pa-color-green;
    margin-right: 0;
  }
}

.publishing-detail-content {
  display: flex;
  gap: 80px;
  padding: 0 24px;
}

.configuration-menu {
  .configuration-menu-content {
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column-reverse;
    padding: 24px 0 0 0;
  }
  a {
    margin: 0 0 16px 0;

    &.active {
      font-weight: bold;
    }
  }
}

.configuration {
  flex: 1;
  padding: 24px 0 0 0;

  & > div {
    &.scroll-target {
      margin: 0;
      padding: 0;
      visibility: hidden;
    }
  }

  .spacer {
    height: 24px;
  }
}

.onboarding-container {
  margin-bottom: 24px;
}
</style>

<style lang="scss">
#application.state-partnerarea-mp-publishingDetail,
#application.state-partnerarea-shop-publishingDetail {
  overflow-y: initial;

  .navbar-main {
    display: none;
  }

  #main-content {
    overflow: initial;
    margin-left: 0;
  }
}
</style>
